import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { log } from '@api/ccc-api-calls'
import { selectors as transactionSelectors } from '@modules/transaction'
import { selectors as authSelectors } from '@modules/auth'
import securityIcon from '@assets/grv-spot-security.svg'

const EaseRedirection = () => {
  const { t } = useTranslation()
  const kioskName = useSelector(transactionSelectors.kioskName)
  const clientCorrelationId = useSelector(transactionSelectors.clientCorrelationId)
  const accessToken = useSelector(authSelectors.accessToken)

  return (
    <div className='content-box'>
      <div style={{ textAlign: 'center' }}>
        <img style={{ width: '96px', height: '96px' }} src={securityIcon} alt='' />
        <h1 id='redirect-title' className='wizard-page-title' style={{ fontWeight: 400 }}>
          {t('easeRedirection.header')}
        </h1>
        <p
          id='redirect-message'
          style={{ marginBottom: 60, fontWeight: 200 }}
          className='grv-text__body--medium'
        >
          {t('easeRedirection.subheader')}
        </p>
        <a
          type='button'
          tabIndex={0}
          className='progress-button'
          style={{ width: '100%', margin: 0 }}
          href={`${process.env.REACT_APP_EASE_REDIRECT}?deviceId=${kioskName}&transactionId=${clientCorrelationId}`}
          onClick={() => {
            log(kioskName, clientCorrelationId, 'TRANSITIONING TO EASE DEEP LINK', accessToken)
          }}
        >
          Continue to the app
        </a>
      </div>
    </div>
  )
}

export default EaseRedirection
