import { call, put, select, takeLatest } from 'redux-saga/effects'
import _ from 'lodash'
import { log } from '@api/ccc-api-calls'
import { defaultReducer } from '@util/default-reducer'
import { actionMatcher } from '@util/saga-action-matcher'
import { handleErrorDispatch } from '@util/error'
import { handleApiErrors } from '@util/api-error-detect'
/* eslint-disable-next-line import/no-cycle */
import { selectors as authSelectors } from './auth'
import { actions as errorActions } from './error'

export const initialState = {
  clientCorrelationId: '',
  customerCheckCentsLimit: 0,
  profileReferenceId: '',
  transactionAssociated: false,
  kioskName: '',
  salt: '',
  errors: {
    clientCorrelationId: {},
    customerCheckCentsLimit: {},
    transactionAssociated: {}
  }
}

export const selectors = {
  kioskName: state => state.transaction.kioskName,
  clientCorrelationId: state => state.transaction.clientCorrelationId,
  customerCheckCentsLimit: state => state.transaction.customerCheckCentsLimit,
  salt: state => state.transaction.salt
}

export const sagas = {
  *fetchClientCorrelationId({ saga: { next } }) {
    const kioskName = yield select(selectors.kioskName)
    const salt = yield select(selectors.salt)
    try {
      const response = yield fetch(`${process.env.REACT_APP_CCC_HOST_OL_ENDPOINT}/transaction`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'kiosk-name': kioskName,
          salt,
          Accept: 'application/json;v=3',
          'Content-Type': 'application/json;v=3'
        }
      }).then(handleApiErrors)
      yield put(next(response.clientCorrelationId))
    } catch (e) {
      yield put(errorActions.setGeneralError(e))
      if (_.has(e, 'response.body.name')) {
        handleErrorDispatch(e.response.body.name, e.response.body.message)
      } else {
        handleErrorDispatch(e.name, e.message)
      }
    }
  },
  *fetchCustomerCheckCentsLimit({ saga: { next } }) {
    try {
      const response = yield fetch(`${process.env.REACT_APP_CCC_HOST_OL_ENDPOINT}/limits`, {
        method: 'GET',
        credentials: 'include',
        headers: {
          Accept: 'application/json;v=3',
          'Content-Type': 'application/json;v=3'
        }
      }).then(handleApiErrors)
      yield put(next(response.customerCheckCentsLimit))
    } catch (e) {
      yield put(errorActions.setGeneralError(e))
      if (_.has(e, 'response.body.name')) {
        handleErrorDispatch(e.response.body.name, e.response.body.message)
      } else {
        handleErrorDispatch(e.name, e.message)
      }
    }
  },
  *offerTransactionAssociation({ payload: history, saga: { next, reject } }) {
    const clientCorrelationId = yield select(selectors.clientCorrelationId)
    const accessToken = yield select(authSelectors.accessToken)
    const kioskName = yield select(selectors.kioskName)
    try {
      log(kioskName, clientCorrelationId, 'offering transaction association', accessToken)
      const response = yield fetch(
        `${process.env.REACT_APP_EXCHANGE_CCC_HOST_OL_ENDPOINT}/associate-transaction`,
        {
          method: 'POST',
          headers: {
            'kiosk-name': kioskName,
            'client-correlation-id': clientCorrelationId,
            Authorization: `Bearer ${accessToken}`,
            Accept: 'application/json;v=3',
            'Content-Type': 'application/json;v=3'
          }
        }
      ).then(handleApiErrors)
      yield put(next(response))
    } catch (e) {
      if (e.statusCode === 403) {
        log(kioskName, clientCorrelationId, e.toString(), accessToken)
        yield call(history.push, '/session-timeout')
      } else if (e.statusCode >= 400 && e.statusCode <= 499) {
        log(kioskName, clientCorrelationId, e.toString(), accessToken)
        yield put(errorActions.setGeneralError(e))
        yield put(reject(e))
      } else {
        yield put(errorActions.setGeneralError(e))
        handleErrorDispatch(e.name, e.message)
      }
    }
  }
}

export const actions = {
  setKioskName: kioskName => ({
    type: actions.setKioskName,
    payload: kioskName,
    reducer: (s, kioskName) => ({ ...s, kioskName })
  }),
  setSalt: salt => ({
    type: actions.setSalt,
    payload: salt,
    reducer: (s, salt) => ({ ...s, salt })
  }),
  fetchClientCorrelationId: () => ({
    type: actions.fetchClientCorrelationId,
    saga: {
      init: sagas.fetchClientCorrelationId,
      next: actions.fetchClientCorrelationIdOk,
      reject: actions.fetchClientCorrelationIdKo
    },
    payload: undefined,
    reducer: s => s
  }),
  fetchClientCorrelationIdOk: clientCorrelationId => ({
    type: actions.fetchClientCorrelationIdOk,
    payload: clientCorrelationId,
    reducer: (s, clientCorrelationId) => ({
      ...s,
      clientCorrelationId,
      errors: initialState.errors
    })
  }),
  fetchClientCorrelationIdKo: e => ({
    type: actions.fetchClientCorrelationIdKo,
    payload: e,
    reducer: (s, e) => ({ ...s, errors: { ...s.errors, ...e } })
  }),

  fetchCustomerCheckCentsLimit: () => ({
    type: actions.fetchCustomerCheckCentsLimit,
    saga: {
      init: sagas.fetchCustomerCheckCentsLimit,
      next: actions.fetchCustomerCheckCentsLimitOk,
      reject: actions.fetchCustomerCheckCentsLimitKo
    },
    payload: undefined,
    reducer: s => s
  }),
  fetchCustomerCheckCentsLimitOk: customerCheckCentsLimit => ({
    type: actions.fetchCustomerCheckCentsLimitOk,
    payload: customerCheckCentsLimit,
    reducer: (s, customerCheckCentsLimit) => ({
      ...s,
      customerCheckCentsLimit,
      errors: initialState.errors
    })
  }),
  fetchCustomerCheckCentsLimitKo: e => ({
    type: actions.fetchCustomerCheckCentsLimitKo,
    payload: e,
    reducer: (s, e) => ({ ...s, errors: { ...s.errors, ...e } })
  }),

  offerTransactionAssociation: history => ({
    type: actions.offerTransactionAssociation,
    saga: {
      init: sagas.offerTransactionAssociation,
      next: actions.offerTransactionAssociationOk,
      reject: actions.offerTransactionAssociationKo
    },
    payload: history,
    reducer: s => s
  }),
  offerTransactionAssociationOk: response => ({
    type: actions.offerTransactionAssociationOk,
    payload: response,
    reducer: s => ({
      ...s,
      transactionAssociated: true,
      errors: initialState.errors
    })
  }),
  offerTransactionAssociationKo: e => ({
    type: actions.offerTransactionAssociationKo,
    payload: e,
    reducer: (s, e) => ({ ...s, errors: { ...s.errors, ...e } })
  })
}

export const sagaWatchers = {
  *fetchClientCorrelationIdWatcher() {
    yield takeLatest(
      actionMatcher(actions.fetchClientCorrelationId),
      actions.fetchClientCorrelationId().saga.init
    )
  },
  *fetchCustomerCheckCentsLimitWatcher() {
    yield takeLatest(
      actionMatcher(actions.fetchCustomerCheckCentsLimit),
      actions.fetchCustomerCheckCentsLimit().saga.init
    )
  },
  *offerTransactionAssociationWatcher() {
    yield takeLatest(
      actionMatcher(actions.offerTransactionAssociation),
      actions.offerTransactionAssociation().saga.init
    )
  }
}

const defaultTransactionReducer = defaultReducer(actions)
export const reducer = (s = initialState, a) => {
  const s2 = defaultTransactionReducer(s, a)
  switch (a.type) {
    case errorActions.clearErrors:
      return { ...s2, errors: initialState.errors }
    default:
      return s2
  }
}
