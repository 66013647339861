import React from 'react'
import Loader from '@components/loader'

const PrintProgress = () => {
  return (
    <>
      <div className='wizard-header-container'>
        <div className='action-bar-container' />
        <div className='wizard-page-title'>Printing Cashier&apos;s Check</div>
      </div>
      <Loader />
    </>
  )
}

export default PrintProgress
