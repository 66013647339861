export async function log(kioskName, ccid, message, accessToken) {
  /* eslint-disable no-console */
  console.log(message)
  if (accessToken) {
    try {
      const endpoint = `${process.env.REACT_APP_EXCHANGE_CCC_HOST_OL_ENDPOINT}/log`
      const res = await fetch(endpoint, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'kiosk-name': kioskName,
          'client-correlation-id': ccid,
          'Content-Type': 'application/json;v=3',
          Accept: 'application/json;v=3',
          Authorization: `Bearer ${accessToken}`
        },
        body: JSON.stringify({
          message
        })
      })
      const status = await res.json()
      if (res.status !== 200) {
        const e = new Error(status.message)
        e.name = status.name
        throw e
      }
    } catch (err) {
      console.error(`Failed to log message for kiosk ${kioskName}: ${err.toString()}`)
    }
  }
}

/**
 * Retrieves transaction status and details.
 *
 * @param {*} kioskName Descriptive name of the kiosk
 * @param {*} clientCorrelationId ID associated with a transaction
 * @param {*} accessToken Bearer token to invoke Exchange API
 * @returns JSON body containing combination transaction identifiers, payee, amount, memo, chosen account type, and account number last four.
 */
export async function getTransactionDetails(
  kioskName,
  clientCorrelationId,
  accessToken,
  prid = ''
) {
  try {
    const endpoint = `${process.env.REACT_APP_EXCHANGE_CCC_HOST_OL_ENDPOINT}/encrypt`
    const headers = {
      Accept: 'application/json;v=3',
      'Content-Type': 'application/json;v=3',
      authorization: `Bearer ${accessToken}`,
      'client-correlation-id': clientCorrelationId,
      'kiosk-name': kioskName
    }
    if (process.env.REACT_APP_NODE_ENV === 'DEV') {
      headers.profilereferenceid = prid
    }

    const res = await fetch(endpoint, {
      method: 'POST',
      credentials: 'include',
      headers
    })
    if (res.status !== 200) {
      throw new Error(`Failed to retrieve transaction details with error code: ${res.status}`)
    }
    return await res.json()
  } catch (err) {
    log(
      kioskName,
      clientCorrelationId,
      `Failed to retrieve transaction details for clientCorrelationId ${clientCorrelationId}: ${err.toString()}`,
      accessToken
    )
    throw err
  }
}

/**
 * Retrieves label reflectiving kiosk's customer-servicable status.
 * @param {*} kioskName Descriptive name of the kiosk
 * @returns label reflecting kiosk's customer-servicable status
 * per ccc.kiosk_status table.
 */
export async function getKioskAndTransactionStatus(kioskName, ccid, accessToken, prid = '') {
  try {
    const endpoint = `${process.env.REACT_APP_EXCHANGE_CCC_HOST_OL_ENDPOINT}/print-status`
    const headers = {
      'kiosk-name': kioskName,
      'client-correlation-id': ccid,
      Accept: 'application/json;v=3',
      'Content-Type': 'application/json;v=3',
      Authorization: `Bearer ${accessToken}`
    }

    if (process.env.REACT_APP_NODE_ENV === 'DEV') {
      headers.profilereferenceid = prid
    }

    const res = await fetch(endpoint, {
      method: 'GET',
      credentials: 'include',
      headers
    })
    const status = await res.json()
    if (res.status !== 200) {
      const e = new Error(status.message)
      e.name = status.name
      throw e
    }
    return {
      kioskStatus: status.kioskStatus,
      transactionStatus: status.transactionStatus,
      moneyMovementStatus: status.moneyMovementStatus
    }
  } catch (err) {
    log(
      kioskName,
      ccid,
      `Failed to retrieve kiosk status for kiosk ${kioskName}: ${err.toString()}`,
      accessToken
    )
    throw err
  }
}

export async function revokeAccessToken(accessToken) {
  try {
    const endpoint = `${process.env.REACT_APP_EXCHANGE_CCC_HOST_OL_ENDPOINT}/revoke-access-token`
    const res = await fetch(endpoint, {
      method: 'POST',
      credentials: 'include',
      headers: {
        Accept: 'application/json;v=3',
        'Content-Type': 'application/json;v=3',
        Authorization: `Bearer ${accessToken}`
      },
      body: JSON.stringify({
        accessToken
      })
    })
    const status = await res.json()
    if (res.status !== 200) {
      const e = new Error(status.message)
      e.name = status.name
      throw e
    }
  } catch (err) {
    console.error(`Error while attempting to revoke customer access token: ${err.toString()}`)
  }
}
