export const getStyleRulesBySelectorText = arrayOfSelectorText => {
  const ret = {}
  /* eslint-disable-next-line no-return-assign */
  arrayOfSelectorText.forEach(e => (ret[e] = {}))
  const styleSheetList = document.styleSheets
  for (let i = 0; i < styleSheetList.length; i++) {
    const styleSheet = styleSheetList.item(i)
    const cssRulesList = styleSheet.cssRules
    for (let j = 0; j < cssRulesList.length; j++) {
      const cssRule = cssRulesList.item(j)
      if (arrayOfSelectorText.includes(cssRule.selectorText)) {
        ret[cssRule.selectorText] = cssRule.style
      }
    }
  }
  return ret
}
