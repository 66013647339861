import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'

import { getStyleRulesBySelectorText } from '@util/get-style-rules-by-selector-text'
import s from './grv-icon.module.scss'

/**
 * @param props
 * @returns {*}
 * @constructor
 */
const GrvIcon = props => {
  const iconRef = useRef()

  useEffect(() => {
    let consumerSuppliedSelectors = []
    let consumerSuppliedStyles = {}
    const consumerOverrodeDefault = {
      display: false,
      width: false,
      height: false,
      color: false
    }
    const consumerOverrides = { display: '', width: '', height: '', color: '' }
    const defaultGrvIconStyles = {
      display: 'inline-block',
      width: '24px',
      height: '24px',
      color: 'green'
    }
    if (props.className && props.className.length > 0) {
      // get all the class names supplied by the GrvIcon consumer and get the styles associated with those classes
      consumerSuppliedSelectors = props.className
        .split(' ')
        .filter(e => !!e.trim())
        .map(e => `.${e}`)
      consumerSuppliedStyles = getStyleRulesBySelectorText(consumerSuppliedSelectors)

      // Only apply default styles if the consumer of GrvIcon hasn't supplied values in-place of the defaults
      Object.values(consumerSuppliedStyles).forEach(v => {
        Object.keys(consumerOverrodeDefault).forEach(k => {
          consumerOverrodeDefault[k] =
            consumerOverrodeDefault[k] || (v[k] !== undefined && v[k].length !== 0)
          if (consumerOverrodeDefault[k]) consumerOverrides[k] = v[k]
        })
      })
    }
    Object.entries(defaultGrvIconStyles).forEach(([styleKey, defaultStyleVal]) => {
      if (!consumerOverrodeDefault[styleKey]) iconRef.current.style[styleKey] = defaultStyleVal
    })
    iconRef.current.style.backgroundImage = getComputedStyle(
      iconRef.current
    ).backgroundImage.replace(
      /fill='%23000000'/g,
      `fill='${encodeURIComponent(iconRef.current.style.color || consumerOverrides.color)}'`
    )
  }, [iconRef, props.className])
  const iconClass = s[`icon-${props.iconClass}`]
  const classNames = props.className ? props.className : ''
  return <div ref={iconRef} className={`${classNames} ${iconClass}`} />
}

GrvIcon.propTypes = {
  className: PropTypes.string,
  iconClass: PropTypes.string.isRequired
}

export default GrvIcon
