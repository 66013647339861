import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Redirect, useLocation } from 'react-router-dom'
import { log } from '@api/ccc-api-calls'
import { useActions } from '@hooks/use-actions'
import { actions as authActions, selectors as authSelectors } from '@modules/auth'
import {
  actions as transactionActions,
  selectors as transactionSelectors
} from '@modules/transaction'
import Loader from './loader'

const Token = () => {
  const isAuthenticated = useSelector(authSelectors.isAuthenticated)
  const accessToken = useSelector(authSelectors.accessToken)
  const [setOauthAuthorizationCode, fetchOauthAccessToken, setKioskName] = useActions([
    authActions.setOauthAuthorizationCode,
    authActions.fetchOauthAccessToken,
    transactionActions.setKioskName
  ])
  const searchParams = new URLSearchParams(useLocation().search)
  const authCodeParam = searchParams.get('code')
  const stateParam = searchParams.get('state')
  const errorParam = searchParams.get('error')
  const state = stateParam ? JSON.parse(decodeURIComponent(stateParam)) : {}
  const kioskName = state['kiosk-name']
  const clientCorrelationId =
    useSelector(transactionSelectors.clientCorrelationId) || state['client-correlation-id']

  useEffect(() => {
    if (authCodeParam && kioskName) {
      setKioskName(kioskName)
      setOauthAuthorizationCode(authCodeParam)
      fetchOauthAccessToken()
    }
  }, [authCodeParam, setOauthAuthorizationCode, fetchOauthAccessToken, setKioskName, kioskName])

  if (isAuthenticated) {
    if (state['redirect-location-after-auth']) {
      log(
        kioskName,
        clientCorrelationId,
        `Redirecting to ${state['redirect-location-after-auth']}`,
        accessToken
      )
      return <Redirect to={{ pathname: state['redirect-location-after-auth'] }} />
    }
    return (
      <div>
        <p>
          You&apos;ve managed to hit the /token page without a redirectLocationAfterAuth in the url
        </p>
        <p>This will never happen under normal operation... what are you up to?</p>
        <Loader />
      </div>
    )
  }

  if (errorParam) {
    return <Redirect to='/error' />
  }

  return (
    <div>
      <Loader />
    </div>
  )
}

export default Token
