import { convertDollarToCents } from './currency'

export const exceededDailyAmountLimits = (
  amount,
  customerPreviousCheckAmountSum,
  customerCheckCentsLimit
) => {
  const formattedAmount = convertDollarToCents(amount)
  const sum = parseInt(formattedAmount) + parseInt(customerPreviousCheckAmountSum)
  return sum > customerCheckCentsLimit
}

export const getPayeeErrors = payee => {
  const errors = {}
  if (!payee) {
    errors.required = 'Payee is a required field'
  } else if (!(payee.length > 1)) {
    errors.min = 'Payee must be greater than 1 character'
  } else if (payee.length > 48) {
    errors.exceededLength = 'Payee cannot be longer than 48 characters'
  } else if (payee) {
    const restricted = ['cash', 'bearer', 'recipient', 'dollars', 'money']
    const payeeWords = payee.toLowerCase().split(/[- ]/g)
    let numRestricted = 0

    /* eslint-disable-next-line array-callback-return */
    payeeWords.map(word => {
      if (restricted.includes(word)) {
        numRestricted++
      }
    })
    if (numRestricted > 0) {
      errors.invalid = 'Payee cannot contain restricted words'
    }
  }
  return errors
}

export const getAmountErrors = (
  amount,
  balance,
  customerPreviousCheckAmountSum,
  customerCheckCentsLimit
) => {
  const errors = {}
  const exceededMaxSum = exceededDailyAmountLimits(
    amount,
    customerPreviousCheckAmountSum,
    customerCheckCentsLimit
  )

  if (!amount && amount !== 0) {
    errors.required = 'Dollars is a required field'
  } else if (convertDollarToCents(amount) > customerCheckCentsLimit) {
    errors.exceededMax = 'This is more money than the maximum allowed per customer per day.'
  } else if (exceededMaxSum) {
    errors.exceededMaxSum = 'This is more money than the maximum allowed per customer per day.' // Potential to change based on customer feedback
  } else if (amount < 1) {
    errors.undershootMin = 'Amount cannot be less than 1 dollar'
  } else if (amount > balance) {
    errors.exceededBalance = 'This is more money than you have in the account you selected.'
  }
  return errors
}

export const getMemoErrors = memo => {
  const errors = {}
  if (memo.length > 40) errors.exceededLength = 'Memo cannot be longer than 40 characters'
  return errors
}

export const isCheckInfoValid = ({
  payee,
  amount,
  memo,
  balance,
  customerPreviousCheckAmountSum,
  customerCheckCentsLimit
}) => {
  const checkErrors = {
    payee: '',
    amount: '',
    memo: ''
  }

  checkErrors.payee = getPayeeErrors(payee)
  checkErrors.amount = getAmountErrors(
    amount,
    balance,
    customerPreviousCheckAmountSum,
    customerCheckCentsLimit
  )
  checkErrors.memo = getMemoErrors(memo)

  const isValid =
    Object.keys(checkErrors.payee).length === 0 &&
    Object.keys(checkErrors.amount).length === 0 &&
    Object.keys(checkErrors.memo).length === 0

  return {
    valid: isValid,
    errors: checkErrors
  }
}

export const preventAmountSymbols = amount => {
  const amountRegex = /[^0-9.]+/g
  return amount.toString().replace(amountRegex, '')
}

export const truncateDecimals = amount => {
  let stringAmount = amount
  if (typeof stringAmount !== 'string') {
    stringAmount = stringAmount.toString()
  }

  const amountBreakdown = stringAmount.split('.')
  const dollars = amountBreakdown[0] ? parseInt(amountBreakdown[0]) : 0
  let cents = amountBreakdown[1]

  if (cents.length > 2) {
    cents = `${cents[0]}${cents[1]}`
  }

  return `${dollars}.${cents}`
}

export const formatAmountWithDecimals = amount => {
  const decimalLength = amount.split('.')[1].length
  let newAmt

  if (decimalLength === 0) {
    const intPortion = amount.split('.')[0]
    if (intPortion.length === 0) {
      newAmt = '.'
    } else {
      newAmt = `${parseInt(intPortion, 10).toString()}.`
    }
  } else {
    newAmt = truncateDecimals(amount)
  }

  return newAmt
}

export const formatAmount = amount => {
  const numericalAmount = preventAmountSymbols(amount)
  let formattedAmount

  if (!numericalAmount) {
    return ''
  }

  if (!numericalAmount.includes('.')) {
    formattedAmount = parseInt(numericalAmount, 10).toString()
  } else {
    formattedAmount = formatAmountWithDecimals(numericalAmount)
  }

  return formattedAmount
}

export const preventSpecialCharacters = str => {
  const regex = /[|,'"~#^]+/g
  return str.toString().replace(regex, '')
}

export const formatPayee = payee => {
  return preventSpecialCharacters(payee)
}

export const formatMemo = memo => {
  return preventSpecialCharacters(memo)
}
