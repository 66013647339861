import { defaultReducer } from '@util/default-reducer'

const initialState = {
  errors: {}
}

export const actions = {
  clearErrors: () => ({
    type: actions.clearErrors,
    payload: undefined,
    reducer: s => ({ ...s, errors: initialState.errors })
  }),
  setGeneralError: e => ({
    type: actions.setGeneralError,
    payload: e,
    reducer: (s, e) => ({ ...s, errors: { ...s.errors, ...e } })
  }),
  clearGeneralErrors: () => ({
    type: actions.clearGeneralErrors,
    payload: undefined,
    reducer: s => s
  })
}

const defaultErrorReducer = defaultReducer(actions)
export const reducer = (state = initialState, action) => {
  return defaultErrorReducer(state, action)
}
