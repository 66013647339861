import React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import { actions as authActions } from '@modules/auth'
import { useActions } from '@hooks/use-actions'

const Login = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const [loginThenGotoTokenComponent] = useActions([authActions.loginThenGotoTokenComponent])

  return (
    <div className='content-container'>
      <form>
        <button
          id='login-button'
          className='action-button'
          style={{ width: 200, margin: '50px auto' }}
          type='submit'
          onClick={e => {
            e.preventDefault()
            loginThenGotoTokenComponent(history)
          }}
        >
          {t('login.header')}
        </button>
      </form>
    </div>
  )
}

export default Login
