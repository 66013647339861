import React from 'react'
import PropTypes from 'prop-types'

const Loader = props => {
  return (
    <div
      style={props?.style ? props.style : {}}
      id='check-printing-loader'
      role='alert'
      aria-label='Loading'
      className={
        props?.additionalClass
          ? `spinner spinner-active ${props.additionalClass}`
          : 'spinner spinner-active'
      }
    />
  )
}

Loader.propTypes = {
  style: PropTypes.PropTypes.shape({
    backgroundColor: PropTypes.string,
    cursor: PropTypes.string
  }),
  additionalClass: PropTypes.string
}

export default Loader
