// https://developer.mozilla.org/en-US/docs/Web/API/WindowOrWorkerGlobalScope/btoa#Unicode_Strings
/**
 * ucs-2 string to base64 encoded ascii
 *
 * @param str An arbitrary string
 * @returns {string}
 */
export function utoa(str) {
  return window.btoa(unescape(encodeURIComponent(str)))
}
