import { useCallback, useState } from 'react'
import { outerHeight } from '@util/outer-height'

function replace(array, index, value) {
  array.splice(index, 1, value)
  return array
}

const determineIsContentOverflowing = (
  arrayOfElementsToMeasure,
  boundingElement,
  arrayOfExtraMeasurements,
  callback
) => {
  let totalHeight = 0
  if (arrayOfElementsToMeasure)
    /* eslint-disable-next-line no-return-assign */
    arrayOfElementsToMeasure.forEach(e => (totalHeight += outerHeight(e)))
  if (arrayOfExtraMeasurements)
    arrayOfExtraMeasurements.forEach(([i, cb]) => {
      if (arrayOfElementsToMeasure[i])
        totalHeight += cb(
          arrayOfElementsToMeasure[i],
          getComputedStyle(arrayOfElementsToMeasure[i])
        )
    })
  const boundHeight = outerHeight(boundingElement)
  const isOverflowing = totalHeight > boundHeight
  if (callback) callback(isOverflowing)
  return isOverflowing
}

export function useIsContentOverflowing(
  boundingElement,
  numElementsToMeasure,
  arrayOfArraysOfExtraMeasurements
) {
  const [elements, setElementsAndSetters] = useState(new Array(numElementsToMeasure))
  /* eslint-disable-next-line no-unused-vars */
  const [isContentOverflowing, setIsContentOverflowing] = useState(false)
  const refs = [...Array(numElementsToMeasure)].map((_, i) => {
    return useCallback(
      element => {
        if (element) setElementsAndSetters(replace(elements, i, element))

        if (elements.every(el => !!el)) {
          determineIsContentOverflowing(
            elements,
            boundingElement,
            arrayOfArraysOfExtraMeasurements,
            setIsContentOverflowing
          )
        }
      },
      [i]
    )
  })
  return [
    () =>
      determineIsContentOverflowing(elements, boundingElement, arrayOfArraysOfExtraMeasurements),
    setIsContentOverflowing,
    ...refs
  ]
}
