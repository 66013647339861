import React, { useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { reinit as storeReinit } from '@src/store'

import headerBackgroundJpg from '@assets/360_bg.jpg'
import { useActions } from '@hooks/use-actions'
import {
  actions as transactionActions,
  selectors as transactionSelector
} from '@modules/transaction'
import { clearSessionTimeout } from '@util/session-timer'
import { formatAmountToCurrency, formatCentsToDollarsAndCents } from '@util/currency'

const getUrlParam = param => {
  const queryString = window.location.search
  const urlParams = new URLSearchParams(queryString)
  const value = urlParams.get(param)
  return value
}

const Landing = () => {
  const { t } = useTranslation()
  const kioskName = useSelector(transactionSelector.kioskName)
  const clientCorrelationId = useSelector(transactionSelector.clientCorrelationId)
  const customerCheckCentsLimit = useSelector(transactionSelector.customerCheckCentsLimit)
  const reinit = useActions(storeReinit)
  const headerBackgroundRef = useRef()
  const [
    fetchClientCorrelationIdOk,
    fetchClientCorrelationId,
    fetchCustomerCheckCentsLimit,
    setKioskName
  ] = useActions([
    transactionActions.fetchClientCorrelationIdOk,
    transactionActions.fetchClientCorrelationId,
    transactionActions.fetchCustomerCheckCentsLimit,
    transactionActions.setKioskName
  ])

  useEffect(() => {
    fetchClientCorrelationId()
    fetchCustomerCheckCentsLimit()
  }, [fetchClientCorrelationId, fetchCustomerCheckCentsLimit])

  clearSessionTimeout()

  const data = {
    client_id: process.env.REACT_APP_OAUTH_CLIENT_ID,
    redirect_uri: process.env.REACT_APP_OAUTH_AUTHORIZATION_CODE_HANDLER_ENDPOINT,
    grant_type: 'authorization_code',
    scope: 'openid',
    'response-type': 'code',
    state: JSON.stringify({
      'redirect-location-after-auth': '/account-selection',
      'kiosk-name': kioskName,
      'client-correlation-id': clientCorrelationId
    })
  }

  useEffect(() => {
    // Clear all state except those loaded from app root since state persists.
    reinit()
    setKioskName(kioskName)
    fetchClientCorrelationIdOk(clientCorrelationId)
    fetchCustomerCheckCentsLimit()
  }, [
    setKioskName,
    fetchClientCorrelationIdOk,
    fetchCustomerCheckCentsLimit,
    kioskName,
    clientCorrelationId,
    reinit
  ])

  useEffect(() => {
    headerBackgroundRef.current.style.backgroundImage = `url(${headerBackgroundJpg})`
  }, [headerBackgroundRef])

  useEffect(() => {
    const continueExperience = getUrlParam('continueExperience')
    window.localStorage.setItem('continueExperience', continueExperience)
  }, [window, getUrlParam])

  return (
    <>
      <div className='header'>
        <div ref={headerBackgroundRef} className='header-background' />
        <h1 id='header-text-landing' className='header-text'>
          {t('landing.header')}
        </h1>
      </div>
      <div className='canvas'>
        <div className='content-box'>
          <h2 className='content-title'>{t('landing.subheader')}</h2>
          <span className='subheader'>{t('landing.fee')}</span>
          <span className='subheader'>
            {formatAmountToCurrency(formatCentsToDollarsAndCents(customerCheckCentsLimit))}
          </span>
          <span className='subheader'>{t('landing.limit')}</span>
          <ol className='landing-page-list'>
            <li>{t('landing.step1')}</li>
            <li>{t('landing.step2')}</li>
            <li>{t('landing.step3')}</li>
            <li>{t('landing.step4')}</li>
          </ol>
          <div id='mobile-app-notice' style={{ marginBottom: 30 }}>
            <span className='subheader'>{t('landing.appNotice1')}</span>
            <span className='subheader' style={{ textDecoration: 'underline' }}>
              {t('landing.appNotice2')}
            </span>
            <span className='subheader'>{t('landing.appNotice3')}</span>
          </div>
          <a
            id='landing-page-button'
            style={{ width: '100%', margin: '0px 0px 16px' }}
            className='action-button'
            href={`${process.env.REACT_APP_AUTH_ENDPOINT}?${new URLSearchParams(data).toString()}`}
          >
            {t('landing.continueToSecureSignIn')}
          </a>
        </div>
      </div>
    </>
  )
}

export default Landing
