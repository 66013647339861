import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import i18nextXhrBackend from 'i18next-xhr-backend'
import I18nextBrowserLanguageDetector from 'i18next-browser-languagedetector'

i18n
  .use(i18nextXhrBackend)
  .use(I18nextBrowserLanguageDetector)
  .use(initReactI18next)
  .init({
    ns: ['translation', 'common'],
    defaultNS: 'translation',
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false // react already does escaping
    }
  })

export default i18n
