import { routes } from '../routes'

const SESSION_TIMEOUT_MS = process.env.REACT_APP_SESSION_TIMEOUT_MS

let history
let sessionTimeout

export const startSessionTimer = historyProp => {
  if (!sessionTimeout) {
    const routeName = routes.sessionTimeout.path
    history = historyProp

    sessionTimeout = setTimeout(() => {
      history.push(routeName) // redirect to /session-timeout
    }, SESSION_TIMEOUT_MS)
  }
}

export const clearSessionTimeout = () => {
  clearTimeout(sessionTimeout)
}
