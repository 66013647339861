import React from 'react'
import { useTranslation } from 'react-i18next'

import { reinit as storeReinit } from '@src/store'
import { useActions } from '@hooks/use-actions'

import timeoutIcon from '@assets/timeout-icon.svg'

import { useSelector } from 'react-redux'
import { selectors as authSelectors } from '@modules/auth'
import { revokeAccessToken } from '@api/ccc-api-calls'

const SessionTimeout = () => {
  const { t } = useTranslation()
  const reinit = useActions(storeReinit)

  const accessToken = useSelector(authSelectors.accessToken)
  const isAuthenticated = useSelector(authSelectors.isAuthenticated)
  if (isAuthenticated) {
    revokeAccessToken(accessToken)
  }

  return (
    <div className='content-box'>
      <div style={{ textAlign: 'center' }}>
        <img
          style={{ width: '75px', height: '75px', marginBottom: '20px' }}
          src={timeoutIcon}
          alt=''
        />
        <h1 className='wizard-page-title'>{t('sessionTimeout.title')}</h1>
        <p style={{ marginBottom: 30 }} className='grv-text__body--small'>
          {t('sessionTimeout.message_1')}
        </p>
        <p style={{ marginBottom: 40 }} className='grv-text__body--small'>
          {t('sessionTimeout.message_2')}
        </p>
        <button
          type='button'
          tabIndex={0}
          className='progress-button'
          style={{ width: '100%', marginTop: '20px' }}
          onClick={() => {
            reinit()
            window.location.replace(process.env.REACT_APP_ACKNOWLEDGMENT_REDIRECT)
          }}
          onKeyDown={event => {
            if (event.key === 'Enter') {
              reinit()
              window.location.replace(process.env.REACT_APP_ACKNOWLEDGMENT_REDIRECT)
            }
          }}
        >
          OK
        </button>
      </div>
    </div>
  )
}
export default SessionTimeout
