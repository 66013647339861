import React from 'react'
import PropTypes from 'prop-types'

const FieldError = ({ errors, name }) => {
  const err = errors[name]

  return (
    !!err &&
    Object.keys(err).length > 0 && (
      <>
        {Object.entries(err).map((v, k) => (
          <span key={k} className='grv-textfield__helper--error'>
            {v[1]}
          </span>
        ))}
      </>
    )
  )
}

FieldError.propTypes = {
  errors: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired
}

export default FieldError
