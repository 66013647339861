import React from 'react'

const ErrorToast = () => {
  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <div role='alert' aria-label='Error alert' className='error-toast-message'>
        There&apos;s been a problem with your request. Please try again.
      </div>
    </div>
  )
}

export default ErrorToast
