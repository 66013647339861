/* eslint-disable consistent-return */
/* eslint-disable no-restricted-globals */
export function getDollars(amount) {
  const invalid = 'Invalid amount'
  if ((!amount && amount !== 0) || isNaN(amount)) return invalid
  // deny anything falsy or not a number

  if (!amount.toString().includes('.'))
    // if no period, it's just a amount part
    return amount.toString()

  const dollarsAndCents = amount.toString().split('.')
  if (dollarsAndCents.length === 2)
    if (dollarsAndCents[1].length > 2)
      // we have amount and cents
      // more than 2 decimal places for cents is invalid
      return invalid
    else return dollarsAndCents[0].toString() || '0'
}

export function getCents(amount) {
  const invalid = 'Invalid amount'
  if ((!amount && amount !== 0) || isNaN(amount)) return invalid
  // deny anything falsy or not a number

  if (!amount.toString().includes('.'))
    // if no period, it's just a amount part
    return '00'

  const dollarsAndCents = amount.toString().split('.')
  if (dollarsAndCents.length === 2)
    if (dollarsAndCents[1].length > 2)
      // we have amount and cents
      // more than 2 decimal places for cents is invalid
      return invalid
    else return parseFloat(amount).toFixed(2).split('.')[1]
}

export function formatCentsToDollarsAndCents(amount) {
  const invalid = 'Invalid amount'
  if ((!amount && amount !== 0) || isNaN(amount)) return invalid

  const stringAmount = amount.toString()
  const dollarsAndCentsArray = [
    stringAmount.slice(0, stringAmount.length - 2),
    stringAmount.slice(stringAmount.length - 2, stringAmount.length)
  ]

  if (!dollarsAndCentsArray[0]) dollarsAndCentsArray[0] = '0'

  if (dollarsAndCentsArray[1].length < 2) dollarsAndCentsArray[1] = `0${dollarsAndCentsArray[1]}`

  return dollarsAndCentsArray.join('.')
}

export function formatAmountToCurrency(amount) {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2
  })

  return formatter.format(amount)
}

export const convertDollarToCents = amount => {
  /* eslint-disable-next-line eqeqeq */
  if (amount || amount == 0) {
    const dollars = amount.toString().split('.')[0]
    let cents = amount.toString().split('.')[1]
    if (!cents) {
      cents = '00'
    } else if (cents.length === 1) {
      cents += '0'
    } else if (cents.length > 2) {
      cents = cents[0] + cents[1]
    }
    return parseInt(dollars + cents)
  }
}
