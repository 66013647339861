export const GLOBAL_ERROR = 'GLOBAL_ERROR'

export function handleErrorDispatch(errTitle, err) {
  document.dispatchEvent(
    new CustomEvent(GLOBAL_ERROR, {
      detail: {
        error: {
          title: errTitle,
          detail: err
        }
      }
    })
  )
}
