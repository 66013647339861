export const handleApiErrors = async response => {
  if (!response.ok) {
    const errorDetails = await response.json()
    const error = new Error(errorDetails.message)
    error.name = errorDetails.name
    error.statusCode = errorDetails.statusCode || response.status
    throw error
  }
  return response.json()
}
